import * as React from 'react';
import { useEffect, useState } from 'react';

import ConsoleOutput from 'bundles/code-evaluator/components/ConsoleOutput';
import type ExecutionTimeSummary from 'bundles/code-evaluator/models/ExecutionTimeSummary';
import ProgressBar from 'bundles/phoenix/components/ProgressBar';

import _t from 'i18n!nls/code-evaluator';

import 'css!./__styles__/CodeEvaluatorSlowProgressIndicator';

const INTERVAL = 100;

interface CodeEvaluatorSlowProgressIndicatorProps {
  executionTimeSummary: ExecutionTimeSummary;
  onCancel: () => void;
}

const CodeEvaluatorSlowProgressIndicator = ({
  executionTimeSummary,
  onCancel,
}: CodeEvaluatorSlowProgressIndicatorProps) => {
  const [timeInMs, setTimeInMs] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const handleProgress = () => {
    if (executionTimeSummary.getPercent(timeInMs) === 100) {
      if (intervalId) {
        clearInterval(intervalId);
      }
    } else {
      setTimeInMs(timeInMs + INTERVAL);
    }
  };

  useEffect(
    () => {
      setIntervalId(setInterval(() => handleProgress(), INTERVAL));

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }, // FIXME: existing react-hooks/exhaustive-deps violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="rc-CodeEvaluatorSlowProgressIndicator">
      <ProgressBar height="3px" width="100%" percentComplete={executionTimeSummary.getPercent(timeInMs)} />

      <div className="execution-step">
        <ConsoleOutput>{executionTimeSummary.getExecutionStep(timeInMs)}</ConsoleOutput>
      </div>

      <button type="button" className="button-link cancel-request" onClick={onCancel}>
        {_t('Cancel')}
      </button>
    </div>
  );
};

export default CodeEvaluatorSlowProgressIndicator;
