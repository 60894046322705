import * as React from 'react';

import classNames from 'classnames';

import ConsoleOutput from 'bundles/code-evaluator/components/ConsoleOutput';
import type { TestCaseOutputContents } from 'bundles/code-evaluator/models/EvaluationResponse';

import 'css!./__styles__/TestCaseOutput';

type Props = {
  output: TestCaseOutputContents;
};

const TestCaseOutput = (props: Props) => {
  const {
    output: { message, isCorrect },
  } = props;

  const classes = classNames('rc-TestCaseOutput', {
    correct: isCorrect,
  });

  return (
    <div className={classes}>
      <ConsoleOutput>{message}</ConsoleOutput>
    </div>
  );
};

export default TestCaseOutput;
