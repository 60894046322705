import * as React from 'react';

import type { ImageDataOutputContents } from 'bundles/code-evaluator/models/EvaluationResponse';

type Props = {
  output: ImageDataOutputContents;
};

const ImageOutput = ({ output }: Props) => {
  const { imageType, data } = output;
  const src = `data:${imageType};base64,${data}`;

  return (
    <div className="rc-ImageOutput">
      <img src={src} alt="" />
    </div>
  );
};

export default ImageOutput;
