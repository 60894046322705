import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import ConsoleOutput from 'bundles/code-evaluator/components/ConsoleOutput';
import type EvaluationResponse from 'bundles/code-evaluator/models/EvaluationResponse';

import _t from 'i18n!nls/code-evaluator';

import 'css!./__styles__/SystemError';

type SystemErrorProps = {
  evaluationResponse: EvaluationResponse;
};

const SystemError = ({ evaluationResponse }: SystemErrorProps) => {
  const { id, errorCodeResult, timeoutErrorResult } = evaluationResponse;

  return (
    <div className="rc-SystemError">
      <ConsoleOutput>
        {errorCodeResult && _t(`System error logged with id ${id}. Please try again later.`)}

        {timeoutErrorResult && (
          <FormattedMessage
            message={_t(
              `Evaluation took more than {timeout} seconds to complete.
              Please try again with a simpler expression.`
            )}
            timeout={timeoutErrorResult.timeout / 1000}
          />
        )}
      </ConsoleOutput>
    </div>
  );
};

export default SystemError;
